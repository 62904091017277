import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../lib/auth';
import { LogIn } from 'lucide-react';
import { useState } from 'react';

export default function LoginButton() {
  const { instance } = useMsal();
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    try {
      if (!import.meta.env.VITE_AZURE_CLIENT_ID || !import.meta.env.VITE_AZURE_TENANT_ID) {
        throw new Error('Azure AD configuration is missing');
      }
      
      if (!import.meta.env.VITE_SUPABASE_URL || !import.meta.env.VITE_SUPABASE_ANON_KEY) {
        throw new Error('Supabase configuration is missing');
      }

      await instance.loginPopup(loginRequest);
    } catch (error: any) {
      console.error('Login failed:', error);
      setError(error.message || 'Failed to sign in');
    }
  };

  return (
    <div className="text-center">
      <button
        onClick={handleLogin}
        className="inline-flex items-center px-6 py-3 bg-brand-navy text-white rounded-lg hover:bg-brand-maroon transition-colors text-lg"
      >
        <LogIn className="w-5 h-5 mr-2" />
        Sign in with Microsoft
      </button>
      {error && (
        <div className="mt-4 text-red-600 bg-red-50 p-3 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
}