import { Configuration, PublicClientApplication, AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { supabase } from './supabase';
import { UserProfile } from '../types';
import { config } from './config';

export const msalConfig: Configuration = {
  auth: {
    clientId: config.azure.clientId,
    authority: `https://login.microsoftonline.com/${config.azure.tenantId}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case 0:
            console.error(message);
            return;
          case 1:
            console.warn(message);
            return;
          case 2:
            console.info(message);
            return;
          case 3:
            console.debug(message);
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};

export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
};

export const photoRequest = {
  scopes: ['User.Read'],
};

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().catch(error => {
  console.error('MSAL initialization failed:', error);
});

const ROLE_MAPPINGS = {
  'Project_Workflow_Project_Managers': 'project_manager',
  'Project_Workflow_Operations_Managers': 'operations_manager'
};

export async function createOrUpdateUserProfile(account: AccountInfo): Promise<UserProfile> {
  try {
    // First, check if user already exists
    const { data: existingUser, error: fetchError } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('azure_id', account.localAccountId)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 is "not found" error
      throw fetchError;
    }

    // Get user details from Microsoft Graph
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });

    const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${response.accessToken}`
      }
    });

    if (!graphResponse.ok) {
      throw new Error('Failed to fetch user data from Microsoft Graph');
    }

    const userData = await graphResponse.json();

    // Determine user role from groups
    const roleResponse = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
      headers: {
        Authorization: `Bearer ${response.accessToken}`
      }
    });

    const { value: groups } = await roleResponse.json();
    const role = groups.find((g: any) => ROLE_MAPPINGS[g.displayName])?.displayName || 'project_manager';

    // Prepare user data
    const userProfile = {
      azure_id: account.localAccountId,
      email: userData.mail || userData.userPrincipalName,
      display_name: userData.displayName,
      role: ROLE_MAPPINGS[role] || 'project_manager',
      job_title: userData.jobTitle,
      department: userData.department,
      photo_url: null,
      last_login: new Date().toISOString()
    };

    let profile;
    if (existingUser) {
      // Update existing user
      const { data: updatedProfile, error: updateError } = await supabase
        .from('user_profiles')
        .update(userProfile)
        .eq('id', existingUser.id)
        .select()
        .single();

      if (updateError) throw updateError;
      profile = updatedProfile;
    } else {
      // Create new user
      const { data: newProfile, error: insertError } = await supabase
        .from('user_profiles')
        .insert([userProfile])
        .select()
        .single();

      if (insertError) throw insertError;
      profile = newProfile;
    }

    return {
      id: profile.id,
      azureId: profile.azure_id,
      email: profile.email,
      displayName: profile.display_name,
      role: profile.role as 'project_manager' | 'operations_manager',
      jobTitle: profile.job_title,
      department: profile.department,
      photoUrl: profile.photo_url,
      createdAt: new Date(profile.created_at),
      lastLogin: new Date(profile.last_login)
    };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenPopup(loginRequest);
      return createOrUpdateUserProfile(account);
    }
    throw error;
  }
}