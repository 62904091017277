import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from '../lib/auth';
import { createContext, useContext, useState } from 'react';
import { UserProfile } from '../types';
import { config } from '../lib/config';

interface AuthContextType {
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile | null) => void;
  error: string | null;
}

export const AuthContext = createContext<AuthContextType>({
  userProfile: null,
  setUserProfile: () => {},
  error: null
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Config validation is now handled by the config module
  try {
    // This will throw an error if config is invalid
    config;
  } catch (err: any) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-xl font-bold text-red-600 mb-4">Configuration Error</h2>
          <p className="text-gray-700 mb-4">{err.message}</p>
          <p className="text-sm text-gray-500">
            Please check your environment variables and ensure they are properly configured.
          </p>
        </div>
      </div>
    );
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AuthContext.Provider value={{ userProfile, setUserProfile, error }}>
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  );
}